import React, { Component } from 'react';
import ReactDOMServer from "react-dom/server";

import Marker from './Marker';

export class Map extends Component {

	constructor(props){
		super(props);

		this.state= {
			map: '',
			marker: false
		}

		this.createMap = this.createMap.bind(this);
		this.getGeoLocation = this.getGeoLocation.bind(this);
		
	}

	componentDidMount(){
		this.dataLoaded();
	}

	dataLoaded(){
		const {selectedPhoto, featureCollection} = this.props;

		// Set defaults to last photo added:
        let startingLat = featureCollection.photos[featureCollection.photos.length-1].lat
        let startingLng = featureCollection.photos[featureCollection.photos.length-1].lng
        let startingMarkerIndex = 0;

        // If there is a selectedPhoto, Find it and pass it:
		if (selectedPhoto){

			for (var i = 0; i < featureCollection.photos.length; i++) {

				if (String(featureCollection.photos[i].id) === selectedPhoto){
					startingLat = featureCollection.photos[i].lat;
					startingLng = featureCollection.photos[i].lng;
					startingMarkerIndex = i;
			    	break;
				}
			}

		}

		// Create the map:
		this.createMap(
    		startingLat,
    		startingLng,
    		featureCollection.photos,
    		startingMarkerIndex
    	);

	}

	createMap(lat, lng, addressPoints, selectedPhotoIndex){
		var L = window.L;
		var mymap = L.map('map').setView([lat, lng], 13);

		L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API, {
			attribution: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			tileSize: 512,
			zoomOffset: -1,
			maxZoom: 18
		}).addTo(mymap);

		var zoom = (selectedPhotoIndex !== 0) ? 8 : -5;
		mymap.zoomIn( zoom );

		// set the state of the map to be used elsewhere
		this.setState({
			map: mymap,
			loaded: true
		});

		// Icon
		var icon = L.icon({
			iconUrl: '/images/map/marker-icon.png',
		});

		// Cluster Setup
		var markers = L.markerClusterGroup();
		var selectedMarker;

		for (var i = 0; i < addressPoints.length; i++) {
			var point = addressPoints[i];
			var title = (point.title) ? point.title : '';
			var marker = L.marker(
				new L.LatLng(point.lat, point.lng), 
				{
					title: title,
					icon
				}
			);
			
			var popupText = (<Marker point={point} />);

			marker.bindPopup( ReactDOMServer.renderToString(popupText) );
			markers.addLayer(marker);

			if (i === selectedPhotoIndex){
				selectedMarker = marker;
			}
		}

		mymap.addLayer(markers);

		//get map height:
		document.getElementById("map")
				.setAttribute("style","height:" + this.returnMapHeight() + "px");
		mymap.invalidateSize();

		if (selectedMarker){
			selectedMarker.openPopup();
		}
		
	}

	returnMapHeight(){
		var body = document.body, html = document.documentElement;
		var pageHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
	
		var header = document.getElementById("top-bar").offsetHeight;
		var footer = document.getElementById("footer").offsetHeight;

		return pageHeight - (header + footer);
	}

	getGeoLocation = () => {
		// returns a promise and will execute when ready:
		navigator.geolocation.getCurrentPosition(this.handleGeoLocation);
	}

	handleGeoLocation = (position) =>{ 
		console.log("the state & position", this.state, position);

		var L = window.L;
		var latlng = L.latLng(position.coords.latitude, position.coords.longitude);

		var mymap = this.state.map;

		mymap.zoomIn(8).panTo(latlng);

		var myIcon = L.icon({
			iconUrl: '/images/map/marker-geo.png',
			popupAnchor: [12, 8]
		});

		// if marker has not been created yet:
		if (this.state.marker === false){
			var marker = L.marker(latlng, {icon: myIcon}).addTo(mymap); 
			marker.bindPopup("Your Location").openPopup();
			this.setState({
				marker: marker
			})
		}else{
			this.state.marker.setLatLng(latlng);
		}

	}

	render(){
		return(
			<div id="map">
				<div 
					className="ol-control geolocate-control"
					onClick={() => this.getGeoLocation()}></div>
			</div>
		)
	}

}