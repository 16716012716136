import React from 'react';
import { Link } from 'react-router-dom';
import { getMapboxLink } from './mapLinkUtils';

const MapLink = ({id, lat, lng}) => {
	if (lat !== "0" && lng !== "0"){
		const url = getMapboxLink(lat, lng)
		return(
			<>
				<Link to={`/map/${id}`} className="photoMap">
					<img src={url}
						alt="See this on the map" />
				</Link>
				<p className="location">{lat},{lng}</p>
			</>
		)
	}
	return <></>
}

export default MapLink;